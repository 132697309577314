import React, { useEffect, useState } from "react";
import { Link, useParams } from "@reach/router";



const Post = () => {
  // don't need request, can just fetch Post data 
  const [post, setPost] = useState({});
  const {id} = useParams()
  // console.log(id)
  useEffect(() => {
    const getPost = async () => {
      const resp = await fetch(
        `https://workers.mjwoo21.workers.dev/api/posts/${id}`
      );
      var postResp = await resp.json();
      postResp = JSON.parse(postResp)
      console.log(postResp)
      setPost(postResp);
    };

    getPost();
  }, [id]);

  if (!Object.keys(post).length) return <div/>;

  return (
    <div class="entire2">
      <h1><center>{post.title}</center></h1>
      <div class="button2">
      <p>
        <button id="create"><Link to="/">Go back</Link></button>
      </p>
      </div>
      <div class="main2">
      <p><i>User: {post.username}</i></p></div>
      <p></p>
      <div class="context">
      <p>{post.text}</p>
      </div>
    </div>
  );
};


export default Post;


// <p>
// <em>Title: </em> <input id="postTitle"></input>
// </p>
// <p>
// <em>Username: </em> <input id="postUsername"></input>
// </p>
// <p>
// <em>Content:</em> <input id="postContent"></input>
// </p>