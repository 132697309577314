import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";



const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://workers.mjwoo21.workers.dev/api/posts",
        {method:'GET'}
      );
      // console.log(resp)
      const postsResp = await resp.json();
      // console.log(postsResp);
      setPosts(postsResp);
    };
    getPosts();
  }, []);

  return (
    <div class="entire">
    <div class="heading">
      <h1><center>Minnie Page</center></h1>
    </div>
    <div class="button">
      <button id="create"><Link to={`/create/`}> Post </Link></button>
    </div>
      
      {posts.length === 0 ?
        <div></div>
        :posts.data.map((post) => {
          post = JSON.parse(post)
          // console.log(post)
          return (
          <p class="main">
          <div key={post.id}>
            <center>
              <Link to={`/posts/${post.id}`}>{post.title}</Link>
            </center>
          </div>
          </p>
        )})
    }
    </div>
  );
};

export default Posts;