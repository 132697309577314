import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";




const Create = ({  }) => {
  const [create, setCreate] = useState({});

  const createPost = async () => {

    var title = document.getElementById("postTitle").value;
    // console.log(title);
    var username = document.getElementById("postUsername").value;
    // console.log(username);
    var content = document.getElementById("postContent").value;
    // console.log(content);
    
    //Content posting
    const requestOptions = {
        mode : 'no-cors', 
        method : 'POST',
        headers: { 'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "title": title, "username": username, "text": content  })

    };
    // const response = await fetch('http://localhost:8787/api/posts', 
    const response = await fetch('https://workers.mjwoo21.workers.dev/api/posts', 
    {
        method : 'POST', 
        body:  JSON.stringify({ "title": title, "username": username, "text": content })
    });

    // console.log(response);
  }
  

  return (
    
    <div class="entire3">
    <h1><center>Create Post</center></h1>

    <div class="button3">
      <button id="create"><Link to="/">Go back</Link></button>
    </div>

    <div class="main3">

      <p>
        <em>Title: </em> <input id="postTitle"></input>
      </p>
      <p>
        <em>Username: </em> <input id="postUsername"></input>
      </p>
      <div class="box">
      <p>
        <em>Content:</em> <input id="postContent"></input>
      </p>
      </div>


    <div class="button4">
      <button onClick={() => createPost()}>Submit</button>
    </div>
    
    </div>
    </div>
  );
};

export default Create;